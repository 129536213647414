import React from 'react'
import { aboutSectionTwoContent } from '../../data/about'

export const AboutSectionTwo = () => {
  return (
      <section className='w-11/12 mx-auto py-6 lg:py-12 space-y-14'>
          <div className='flex flex-col md:flex-row items-center space-y-4 md:space-y-0'>
              <h3 className='text-[30px] lg:text-[35px] xl:text-[54px] font-[700]'>{aboutSectionTwoContent.titleOne.normal}<span className='text-[#FB670D]'>{aboutSectionTwoContent.titleOne.coloured}</span>{aboutSectionTwoContent.titleOne.normalContinuation}</h3>
              <img className='object-cover md:w-1/2' src={aboutSectionTwoContent.imageOne} alt="peopleImage"/>
          </div>

          <div className='flex flex-col md:flex-row items-center'>
              <img className='md:w-1/2' src={aboutSectionTwoContent.imageTwo} alt='rocketImage' />
              <div className='mt-5 md:mt-0'>
                  <p className='text-[#FB670D] font-[800] text-[17px]'>{aboutSectionTwoContent.titleTwo.badgeOne}</p>
                  <h4 className='text-[#442276] text-[25px] xl:text-[37px] font-[600] mt-5'>{aboutSectionTwoContent.titleTwo.text}</h4>
              </div>
          </div>
    </section>
  )
}
