import React from 'react'
import { aboutBannerContent } from '../../data/about';
import "./about.css";

export const AboutBanner = () => {
  return (
    <section className='lg:h-[550px] 2xl:h-[680px] aboutBanner flex flex-col items-center justify-center space-y-6 p-4 pb-8 text-white'>
          <h1 className='text-[30px] lg:w-10/12 lg:text-center text-black lg:text-[40px] xl:text-[48px] font-[700]'>{aboutBannerContent.title.normal}<span className='text-white'>{aboutBannerContent.title.coloured}</span>{aboutBannerContent.title.normalContinuation}</h1>
          <p className='lg:w-3/5 text-[18px] lg:text-center leading-[40px]'>{aboutBannerContent.paraLine}</p>
          {/* <button className='bg-white inline text-black py-[11px] px-[25px] rounded-[48px]'>{aboutBannerContent.button}</button> */}
    </section>
  )
}
