import React from "react";
import { AboutPage } from "../components/About/AboutPage";
import { Layout } from "../components/Layout";
import "../components/index.css";

const About = () => {
    return (
        <Layout>
            <AboutPage />
        </Layout>
    )
}

export default About;