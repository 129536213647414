import React from 'react'
import { AboutBanner } from './AboutBanner'
import { AboutSectionThree } from './AboutSectionThree'
import { AboutSectionTwo } from './AboutSectionTwo'

export const AboutPage = () => {
  return (
      <div>
          <AboutBanner />
          <AboutSectionTwo />
          <AboutSectionThree />
    </div>
  )
}
