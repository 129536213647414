import aboutUsPeopleImage from "../images/aboutUsPeopleImage.svg";
import aboutUsRocketImage from "../images/aboutUsRocketImage.svg";


export const aboutBannerContent = {
    title: {
        normal: "Facilitating organizations to ",
        coloured: "become more agile and teams to stay connected ",
        normalContinuation:"and professionally engaged. "
    },
    paraLine: "Spectrawise believes in empowering organizations with best-in-market AI-driven analytical Software, PRISM, that combines intelligent reporting & dashboards to simply frontline workforce management.",
    button:"Learn more"
}

export const aboutSectionTwoContent = {
    titleOne: {
        normal: "Leverage the ",
        coloured: "power of the cloud-based solution to optimizes your workforce processes ",
        normalContinuation:"and productivity."
    },
    imageOne: aboutUsPeopleImage,
    titleTwo: {
        badgeOne: "OUR VISION",
        text:"Spectrawise is committed to providing digital solutions in workforce management that offer actionable insights based on data analytics and applying complex algorithms."
    },
    imageTwo:aboutUsRocketImage
}

export const aboutSectionContentThree = {
    title: {
        coloured: "Organizations need to deploy the ",
        normal:"best employee database software with immaculate and accurate data/record management capabilities."
    },
    smallText: "Time to have a 360-degree view across your organization through insightful reporting and analytics.",
    paraLine:"Spectrawise Technologies Private Limited's Prism is a people-centric, technology-driven software that reduces manual workload and eliminates the struggle to navigate repetitive tasks. As a workforce automation software company, Spectrawise Technologies Private Limited strives to streamline organizational different level communication, automating workforce management and reducing redundant activities with minimal human intervention. Our mission is to increase operational efficiency by sating employee experience via seamlessly transparent reporting and simplified workforce management processes, eradicating complexities."
}